<app-aside></app-aside>


<div class="page-title-overlap pt-4 bg-dark">
  <app-jumbo></app-jumbo>
</div>

<section class="bg-dark">

  <div class="container pb-5">
    <div class="bg-light box-shadow-lg corners-rounded">
      <div class="row">
        <!-- Sidebar-->
        <aside class="col-lg-3">
          <app-menu></app-menu>
        </aside>
        <!-- Content-->
        <section class="col-lg-9 pt-lg-4">
          <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
            <div class="row">


              <div class="col-lg-12">
                <div class="mt-lg-2 pb-2">
                  <h2 class="mt-lg-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                      class="bi bi-play-circle mb-1" style="color: #6610f2;" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path
                        d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                    </svg>
                    Tutorial de uso
                  </h2>

                </div>
                <div class="row">

                  <div class="col-12">
                    <div class="card pb-4 mb-4" style="width: 100%;">
                      <div class="card-body">
                        <p class="card-text mt-3">
                          En los siguientes videos, descubrirás de manera detallada cómo utilizar de manera eficiente el
                          panel de administración de ASEMBIS.org. Este recurso te permitirá tener el control
                          completo
                          sobre las diversas funcionalidades disponibles. <br><br>Te recomendamos darle clic en el
                          icono de
                          pantalla completa en el video y asegurarse de tener la calidad más alta activada para que se
                          aprecien mejor los detalles.</p>

                        <div class="row">

                          <div class="col-12">
                            <h5 class="mb-3 mt-5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-arrow-right-circle-fill mb-1 me-2" style="color: #6610f2;"
                                viewBox="0 0 16 16">
                                <path
                                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                              Inicio de sesión
                            </h5>
                            <div>
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/mhxcXZfb4pw"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            </div>
                          </div>

                          <div class="col-12">
                            <h5 class="mb-3 mt-5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-arrow-right-circle-fill mb-1 me-2" style="color: #6610f2;"
                                viewBox="0 0 16 16">
                                <path
                                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                              Especialidades
                            </h5>
                            <div>
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/s2N1npLSdB4"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            </div>
                          </div>


                          <div class="col-12">
                            <h5 class="mb-3 mt-5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-arrow-right-circle-fill mb-1 me-2" style="color: #6610f2;"
                                viewBox="0 0 16 16">
                                <path
                                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                              Sucursales
                            </h5>
                            <div>
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/WfBWhw0I0Nk"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            </div>
                          </div>

                          <div class="col-12">
                            <h5 class="mb-3 mt-5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-arrow-right-circle-fill mb-1 me-2" style="color: #6610f2;"
                                viewBox="0 0 16 16">
                                <path
                                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                              Promociones
                            </h5>
                            <div>
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/Bzq8v8azGe8"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            </div>
                          </div>

                          <div class="col-12">
                            <h5 class="mb-3 mt-5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-arrow-right-circle-fill mb-1 me-2" style="color: #6610f2;"
                                viewBox="0 0 16 16">
                                <path
                                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                              Anteojos
                            </h5>
                            <div>
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/z-N5mL2AFzc"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            </div>
                          </div>

                          <div class="col-12">
                            <h5 class="mb-3 mt-5">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                class="bi bi-arrow-right-circle-fill mb-1 me-2" style="color: #6610f2;"
                                viewBox="0 0 16 16">
                                <path
                                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                              Blog
                            </h5>
                            <div>
                              <iframe width="560" height="315" src="https://www.youtube.com/embed/HDweyToPEpY"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen></iframe>
                            </div>
                          </div>

                          <div class="col-12">
                            <p class="card-text mt-5">
                              Si por algún motivo no puede reproducir los videos ingrese a el siguiente link:
                            </p><a
                              href="https://drive.google.com/drive/folders/1zY6RWUFkLUPWfhHqznGq0fJWn6Wy_YKi?usp=sharing"
                              target="_blank">OPEN FOLDER</a>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  </div>
</section>