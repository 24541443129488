<app-aside></app-aside>

<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">


            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2 mb-5">
                <h1 class="mt-lg-4">Editor de portadas</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Agregue las portadas de los componentes correspondientes.</p>

                </div>

              </div>
            </div>
            <div class="col-lg-12 form-group" *ngIf="msm_success">
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                Se actualizó los datos de las configuraciones correctamente.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="col-lg-12 form-group" *ngIf="msm_error && (msm_error != '')">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                {{msm_error}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>

            <div class="col-lg-12">
              <form #portadaForm="ngForm" (ngSubmit)="onSubmit(portadaForm)">
                <div class="card border-0 box-shadow">

                  <div class="card-body">
                    <h3 class="mb-3">Portada principal</h3>
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Tipo de
                            portada</label>
                          <div class="col-md-10">
                            <select class="form-control custom-select" id="select-input" [(ngModel)]="portada.tipo"
                                    name="tipo" required>
                              <option value="Portada">Imagen / Video</option>
                              <option value="Slider">Slider</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Portada</label>
                          <div class="col-md-10">
                            <div class="cz-file-drop-area">

                              <div *ngIf="desktopFileType == 'image'"
                                   class="cz-file-drop-preview img-thumbnail rounded">
                                <img [src]="imgSelectPortada || url+'portada/resources/thumbnails/'+portada?.portada"
                                     alt="04.jpg">
                              </div>
                              <div *ngIf="desktopFileType == 'video'" style="width: 100%;"
                                   class="cz-file-drop-preview img-thumbnail rounded">
                                <video *ngIf="imgSelectPortada" style="width: 100%;" autoplay loop [muted]="true">
                                  <source [src]="imgSelectPortada"
                                          [type]="filePortada.type">
                                </video>
                                <video *ngIf="!imgSelectPortada" style="width: 100%;" autoplay loop [muted]="true">
                                  <source [src]="url+'/portada/resources/thumbnails/' + portada?.portada"
                                          [type]="'video/' + portada?.portada?.split('.').pop()">
                                </video>
                              </div>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelected_portada($event)">
                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 1700px X 740px - Peso recomendado:
                              200kb</p>

                          </div>
                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Portada
                            Mobile</label>
                          <div class="col-md-10">
                            <div class="cz-file-drop-area">
                              <div *ngIf="mobileFileType == 'image'" class="cz-file-drop-preview img-thumbnail rounded">
                                <img
                                  [src]="imgSelectPortadaMobile ||url+'portada/resources/thumbnails/'+portada?.portadaMobile"
                                  alt="04.jpg">
                              </div>
                              <div *ngIf="mobileFileType == 'video'" style="width: 100%;"
                                   class="cz-file-drop-preview img-thumbnail rounded">
                                <video *ngIf="imgSelectPortadaMobile" style="width: 100%;" autoplay loop [muted]="true">
                                  <source [src]="imgSelectPortadaMobile"
                                          [type]="file_portada_mobile.type">
                                </video>
                                <video *ngIf="!imgSelectPortadaMobile" style="width: 100%;" autoplay loop
                                       [muted]="true">
                                  <source [src]="url+'/portada/resources/thumbnails/' + portada?.portadaMobile"
                                          [type]="'video/' + portada?.portadaMobile?.split('.').pop()">
                                </video>
                              </div>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelectedPortadaMobile($event)">

                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 600px X 990px - Peso recomendado:
                              100kb</p>

                          </div>

                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Slider</label>
                          <div class="col-md-10">
                            <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'"
                                 (change)="onSelectSlider($event)">
                              <ngx-dropzone-label>
                                <div>
                                  <h2>Arrastra 3 imagenes aquí</h2>
                                </div>
                              </ngx-dropzone-label>
                              <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                                          *ngFor="let f of filesSlider" [file]="f" [removable]="true"
                                                          (removed)="onRemoveSlider(f)">

                              </ngx-dropzone-image-preview>
                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 1700px X 740px - Peso recomendado:
                              200kb c/u</p>
                          </div>
                        </div>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Slider
                            Mobile</label>
                          <div class="col-md-10">
                            <div class="custom-dropzone" ngx-dropzone [accept]="'image/*'"
                                 (change)="onSelectSliderMobile($event)">
                              <ngx-dropzone-label>
                                <div>
                                  <h2>Arrastra 3 imagenes aquí</h2>
                                </div>
                              </ngx-dropzone-label>
                              <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"
                                                          *ngFor="let f of filesSliderMobile" [file]="f"
                                                          [removable]="true" (removed)="onRemoveSliderMobile(f)">

                              </ngx-dropzone-image-preview>
                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 600px X 990px - Peso recomendado: 100kb
                              c/u</p>
                          </div>
                        </div>


                        <h3 class="mt-3 mb-3">Portada Mujer</h3>

                        <div class="form-group row align-items-center" id="logo-container">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Portada Mujer</label>
                          <div class="col-md-10">
                            <div class="cz-file-drop-area">

                              <div  class="cz-file-drop-preview img-thumbnail rounded">
                                <img [src]="imgSelectPortadaMujer || url+'portada/resources/thumbnails/'+portada?.portadaMujer"
                                     alt="04.jpg">
                              </div>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelectedPortadaMujer($event)">
                            </div>
                            <p class="small text-muted mt-2">Tamaño requerido: 600px X 600px - Peso recomendado:
                              100kb</p>

                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary btn-sm mb-2 mr-1" type="submit">Actualizar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div>
