<footer [ngClass]="{'pt-4': true, 'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">


  <!--  Desktop - ipad - ipad pro footer-->
  <section class="none-phone">
    <div class="container">
      <div class="row">


        <div class="col-md-6 col-lg-3">
          <img class="mt-5" *ngIf="!global.color_filter" src="assets/img/newlogo-white.svg" alt="Logo de ASEMBIS"
            width="75%">
          <img class="mt-5" *ngIf="global.color_filter" src="assets/img/newlogo-black.svg" alt="Logo de ASEMBIS"
            width="75%">

          <h6
            [ngClass]="{'ms-1': true, 'white': !global.color_filter, 'black': global.color_filter,'light-f': !global.dislex, 'dislex': global.dislex}">
            MARCA
            REGISTRADA ®</h6>

        </div>

        <div class="col-md-6 col-lg-3">
          <h3
            [ngClass]="{'text-big mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex}">
            EXPLORAR</h3>

          <ul style="padding: 0; list-style-type: none;">

            <li [routerLink]="['']" href=""
              [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Inicio
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <!--<li [routerLink]="['/citas']"
                [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                 [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Agendar
                cita
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
              </a></li>-->

            <li [routerLink]="['/sobreasembis']" href="/sobreasembis"
              [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Sobre
                ASEMBIS
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <li [routerLink]="['/trabajosocial']" href="/trabajosocial"
              [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Trabajo
                Social
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <li [routerLink]="['/especialidades']" href="/especialidades"
              [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Especialidades
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <!--<li [routerLink]="['/planesdesalud']"
                [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                 [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Servicios
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
              </a></li>-->

            <!--  <li [routerLink]="['/encuentrenos']"
                  [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                <a style="text-decoration: none;"
                   [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Encuentrenos
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                       class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                    <path
                      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                  </svg>
                </a></li>-->

            <!--<li [routerLink]="['/promos']"
                [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                 [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Promociones
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
              </a></li>-->

            <li [routerLink]="['/convenios']" href="/convenios"
              [ngClass]="{'mb-2': true,'cursor': global.cursor_btn,'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              <a style="text-decoration: none;"
                [ngClass]="{'': true, 'foot-cursor': !global.cursor_btn,'cursor': global.cursor_btn, 'color-option': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">Convenios
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>
            <li
            [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
            <a style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#politicasmodal">Política de privacidad
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                <path
                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
              </svg>
            </a>
          </li>
          </ul>
        </div>

        <div class="col-md-6 col-lg-3">
          <h3
            [ngClass]="{'text-normal mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg>
            TELÉFONOS
          </h3>
          <a>
            <h5
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Llamadas: +506 2285 5881</h5>
          </a>
          <a>
            <h5
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Whatsapp: +506 8814 5881</h5>
          </a>
          <h3
            [ngClass]="{'bigger-t text-normal mt-3': true, 'white': !global.color_filter, 'black': global.color_filter}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
              <path
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
            </svg>
            CORREO
          </h3>
          <a>
            <h5
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              contacto&#64;asembis.org</h5>
          </a>
        </div>

        <div class="col-md-6 col-lg-3">
          <h3
            [ngClass]="{'text-big mt-5': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">
            REDES SOCIALES</h3>

          <a [ngClass]="{'text-decoration-none': true, 'cursor': global.cursor_btn}" style="cursor: pointer;"
            href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <h6
              [ngClass]="{'mt-2': true, 'cursor': global.cursor_btn,'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-instagram me-2" viewBox="0 0 16 16">
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
              </svg>
              clinicas_asembis
            </h6>
          </a>

          <a [ngClass]="{'text-decoration-none': true, 'cursor': global.cursor_btn}" style="cursor: pointer;"
            href="https://www.facebook.com/Asembis/" target="_blank">
            <h6
              [ngClass]="{'mt-2': true,'cursor': global.cursor_btn, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                class="bi bi-facebook me-2" viewBox="0 0 16 16">
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
              asembis
            </h6>
          </a>
        </div>

        <div class="col-md-12 mt-5 mb-md-3">
          <a style="text-decoration: none;" target="_blank" href="https://linkdesign.cr/">
            <h5
              [ngClass]="{'roboto mt-5 text-small': true, 'cursor': global.cursor_btn, 'white': !global.color_filter, 'black': global.color_filter}"
              style="text-align: right;">
              Desarrollado por <strong>LINK DESIGN</strong>
            </h5>
          </a>
          <p class="white mb-3" style="text-align: right;">This site is protected by reCAPTCHA Enterprise<br> and the
            Google
            <a class="white" href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a class="white" href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>
        </div>

      </div>
    </div>
  </section>

  <!--  Phone footer-->

  <section class="only-phone">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <img class="mt-4 mb-2" *ngIf="!global.color_filter" src="assets/img/newlogo-white.svg" alt="Logo de ASEMBIS"
            width="60%" style="margin-left: 20%;">
          <img class="mt-4 mb-2" *ngIf="global.color_filter" src="assets/img/newlogo-black.svg" alt="Logo de ASEMBIS"
            width="60%" style="margin-left: 20%;">

          <h6
            [ngClass]="{'ms-2 mb-3 text-center': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': global.dislex, 'dislex': global.dislex}">
            MARCA REGISTRADA ®</h6>
        </div>


        <div class="col-9 text-center border-bottom mb-3">
          <h3
            [ngClass]="{' mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bigger-t': global.dislex, 'dislex': global.dislex}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
            </svg>
            Teléfono
          </h3>
          <a>
            <h5
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Llamadas: +506 2285 5881</h5>
          </a>
          <a>
            <h5
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              Whatsapp: +506 8814 5881</h5>
          </a>

          <h3
            [ngClass]="{'mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bigger-t': global.dislex, 'dislex': global.dislex}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
              class="bi bi-envelope-fill" viewBox="0 0 16 16">
              <path
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
            </svg>
            Correo
          </h3>
          <a>
            <h5
              [ngClass]="{'white mb-5': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              contacto&#64;asembis.org</h5>
          </a>

          <div class="d-flex justify-content-center mb-5">
            <a href="https://www.facebook.com/Asembis/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                [ngClass]="{'bi bi-facebook me-5': true, 'white': !global.color_filter, 'black': global.color_filter}"
                viewBox="0 0 16 16">
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
              </svg>
            </a>

            <a href="https://www.instagram.com/clinicas_asembis/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                [ngClass]="{'bi bi-instagram': true, 'white': !global.color_filter, 'black': global.color_filter}"
                viewBox="0 0 16 16">
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
              </svg>
            </a>

            <a href="https://api.whatsapp.com/send?phone=50688145881." target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
                [ngClass]="{'bi bi-whatsapp ms-5': true, 'white': !global.color_filter, 'black': global.color_filter}"
                viewBox="0 0 16 16">
                <path
                  d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
              </svg>
            </a>
          </div>
        </div>


        <div class="col-9 text-center">
          <h3
            [ngClass]="{'mt-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bigger-t': global.dislex, 'dislex': global.dislex}">
            Explorar</h3>
          <ul style="padding: 0; list-style-type: none;">

            <li [routerLink]="['']" href=""
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Inicio
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <!--<li [routerLink]="['/citas']"
                [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Agendar cita
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
              </a></li>-->

            <li [routerLink]="['/sobreasembis']" href="/sobreasembis"
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Sobre ASEMBIS
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <li [routerLink]="['/trabajosocial']" href="/trabajosocial"
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Trabajo Social
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <li [routerLink]="['/especialidades']" href="/especialidades"
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Especialidades
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

           

            <!-- <li [routerLink]="['/planesdesalud']"
                [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Servicios
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
              </a></li>-->

            <!--<li [routerLink]="['/encuentrenos']"
                [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Encuentrenos
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                </svg>
              </a></li>-->

            <!-- <li [routerLink]="['/promos']"
                 [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
               <a style="cursor: pointer;">Promociones
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                   <path
                     d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                 </svg>
               </a></li>-->

            <li [routerLink]="['/convenios']" href="/convenios"
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
              <a style="cursor: pointer;">Convenios
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                  class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                  <path
                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                </svg>
              </a>
            </li>

            <li
            [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
            <a style="cursor: pointer;" data-bs-toggle="modal" data-bs-target="#politicasmodal">Política de privacidad
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-arrow-right-circle-fill ms-2" viewBox="0 0 16 16">
                <path
                  d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
              </svg>
            </a>
          </li>

          </ul>
        </div>

        <div class="col-12">
          <a href="https://linkdesign.cr/" target="_blank" style="text-decoration: none;">
            <h4
              [ngClass]="{'roboto mt-5 text-center text-normal': true, 'white': !global.color_filter, 'black': global.color_filter}">
              Desarrollado por <strong>LINKDESIGN</strong>
            </h4>
          </a>
          <p class="white text-center mb-5 pb-4" style="text-align: right;">This site is protected by reCAPTCHA
            Enterprise and the Google
            <a class="white" href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a class="white" href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>
        </div>

      </div>
    </div>
  </section>


</footer>


<!-- Menu fixed for phones-->

<!--
<section class="bg-white border-top fixed-bottom none-desktop">
  <div class="container">
    <div class="row">

      <div class="col-4 text-center">
        <a>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               [ngClass]="{'bi bi-calendar3  mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
               viewBox="0 0 16 16">
            <path
              d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
            <path
              d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
          </svg>
        </a>
        <a><h5
          [ngClass]="{'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"
          style="font-size: 78%;">NUEVA CITA</h5></a>
      </div>

      <div class="col-4 text-center border-start border-end">
        <a>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor"
               [ngClass]="{'bi bi-tags-fill mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
               viewBox="0 0 16 16">
            <path
              d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
            <path
              d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043-7.457-7.457z"/>
          </svg>
        </a>
        <a><h5
          [ngClass]="{'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"
          style="font-size: 78%;">PROMOCIONES</h5></a>
      </div>

      <div class="col-4 text-center">
        <a>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
               [ngClass]="{'bi bi-eyeglasses mt-2': true, 'green': !global.color_filter, 'black': global.color_filter}"
               viewBox="0 0 16 16">
            <path
              d="M4 6a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm2.625.547a3 3 0 0 0-5.584.953H.5a.5.5 0 0 0 0 1h.541A3 3 0 0 0 7 8a1 1 0 0 1 2 0 3 3 0 0 0 5.959.5h.541a.5.5 0 0 0 0-1h-.541a3 3 0 0 0-5.584-.953A1.993 1.993 0 0 0 8 6c-.532 0-1.016.208-1.375.547zM14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"/>
          </svg>
        </a>
        <a><h5
          [ngClass]="{'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}"
          style="font-size: 78%;">ÓPTICAS</h5></a>
      </div>

    </div>
  </div>
</section>-->




<!-- Modal -->
<div class="modal fade" id="politicasmodal" tabindex="-1" aria-labelledby="politicasmodalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2 [ngClass]="{'bigger-t title-line-height mt-3 ms-5': true, 'bold-f': !global.dislex, 'dislex': global.dislex, 'turquesa':!global.color_filter,
          'black': global.color_filter}">Políticas de privacidad</h2>
        <p
          [ngClass]="{'mb-3 p-5': true,'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          ASEMBIS, a través de su Sitio Web, podrá solicitar información personal (en los casos que se requiera, se le
          hará saber). Por lo tanto, la utilización de nuestro Sitio Web supone que usted reconoce haber leído y
          aceptado nuestra Política de Privacidad y Seguridad que aquí se presenta, así como nuestros Términos y
          Condiciones.<br><br>
          Política de Privacidad y Seguridad<br><br> Recolección y uso de la información<br><br>

          La política de Privacidad y Seguridad de ASEMBIS aplica a la recopilación y uso de la información que usted
          nos suministre a través de nuestro Sitio Web, dicha información será utilizada únicamente por ASEMBIS en el
          cumplimiento de sus fines.<br><br>

          ASEMBIS utiliza sistemas automatizados para la detección y prevención de ataques informáticos, minimizando la
          posibilidad de sufrir daños o alteraciones en la información disponible en el Sitio Web. Los mismos nos
          permiten generar reportes y bitácoras de los accesos indebidos desde y hacia nuestro sitio.<br><br>

          La información se utilizará con el propósito para la que fue solicitada. ASEMBIS respeta su derecho a la
          privacidad, y no proveerá a terceras personas la información personal de sus usuarios sin su consentimiento, a
          no ser que sea requerido por las leyes vigentes.<br><br>

          <strong>Cookies</strong><br>
          Utilizamos tecnología de rastreo con “cookies”. El Usuario puede utilizar la configuración de su navegador
          para deshabilitar el uso de “cookies”. Si son deshabilitadas podrá seguir navegando en nuestro Sitio Web, pero
          con algunas restricciones.<br><br>

          <strong>Seguridad</strong><br>
          La transferencia de datos personales y la integridad del Sitio Web la protegemos utilizando tecnología de
          codificación SSL (Secure Sockets Layer) de 128 bits como canal seguro en las transacciones que usted realiza.<br><br>

          Nuestra autoridad certificadora es Let's Encrypt y el dominio para el cual se emitió este certificado es
          www.asembis.org<br><br>

          El dueño de los certificados es ASEMBIS con domicilio en, detrás de la Iglesia Católica de Purral, San José,
          Guadalupe, 10801. El período de validez es del July 22, 2024 al October 20, 2024, con renovaciones automaticas
          cada 3 meses.<br><br>

          Dado que ningún sistema puede garantizar seguridad completa, se trata de mantener la información que el
          Usuario suministra o accede lo más segura posible, incluyendo su seguridad física en la ubicación del servidor
          donde la información está almacenada.<br><br>

          <strong>Actualización de datos</strong><br>


          Periódicamente realizamos revisión y actualización de los datos que usted nos proporciona a través del Sitio
          Web que puedan ser modificados.<br><br>

          <strong>Actualización de políticas</strong><br>


          La política de privacidad y seguridad de ASEMBIS se revisa con una frecuencia mensual. Podremos modificarla
          periódicamente, en dicho caso comunicaremos la política modificada en nuestro Sitio Web.<br><br>

          <strong>Contáctenos</strong> <br><br>


          ASEMBIS está comprometida en proteger los datos que usted nos proporcione. Si tiene alguna pregunta,
          observación o inquietud sobre nuestra Política de Privacidad y Seguridad o nuestros Términos y Condiciones,
          póngase en contacto con nosotros enviándonos un mensaje de correo electrónico a
          <strong>contacto&#64;asembis.org</strong></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar modal</button>
      </div>
    </div>
  </div>
</div>