<app-nav></app-nav>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <img class="icon-cover-page" src="assets/img/icons/icon-planes.png">
        <h1
          [ngClass]="{'bigger-t mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          CONVENIOS ASEMBIS</h1>
      </div>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row d-flex justify-content-center">

      <div class="col-11 col-md-6 col-lg-3">
        <div
          [ngClass]="{'card card-data mb-2 mb-md-2 mb-lg-4 mt-5 mt-md-5 mt-lg-5 mb-2 border-0 corners-rounded bg-gray': true}"
          style="width: 100%;">
          <div class="row g-0">
            <div class="col-3 col-md-4">
              <img src="assets/img/icons/con-1.png" class="img-fluid rounded-start mt-4"
                   style="width: 60%; margin-left: 15%;">
            </div>
            <div class="col-9 col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'text-normal mb-1 mt-3': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    +2.000 convenios</h5>
                </div>
                <p
                  [ngClass]="{'p-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Con empresas de todos los sectores del mercado.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 col-md-6 col-lg-3">
        <div
          [ngClass]="{'card card-data mb-2 mb-md-2 mb-lg-4 mt-2 mt-md-5 mt-lg-5 mb-2 border-0 corners-rounded bg-gray': true}"
          style="width:  100%;">
          <div class="row g-0">
            <div class="col-3 col-md-4">
              <img src="assets/img/icons/con-2.png" class="img-fluid rounded-start mt-4"
                   style="width: 60%; margin-left: 15%;">
            </div>
            <div class="col-9 col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'text-normal mb-1 mt-3': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    +50.000 personas</h5>
                </div>
                <p
                  [ngClass]="{'p-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Beneficiadas con nuestros convenios empresariales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 col-md-6 col-lg-3">
        <div
          [ngClass]="{'card card-data mb-2 mb-md-2 mb-lg-4 mt-2 mt-md-3 mt-lg-5 mb-2 border-0 corners-rounded bg-gray': true}"
          style="width:  100%;">
          <div class="row g-0">
            <div class="col-3 col-md-4">
              <img src="assets/img/icons/con-3.png" class="img-fluid rounded-start mt-4"
                   style="width: 60%; margin-left: 15%;">
            </div>
            <div class="col-9 col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'text-normal mb-1 mt-3': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    Calidad asegurada</h5>
                </div>
                <p
                  [ngClass]="{'p-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex,'sizetext': global.sizetext}">
                  Todos nuestros servicios médicos son de calidad.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-11 col-md-6 col-lg-3">
        <div
          [ngClass]="{'card card-data mb-5 mb-md-2 mb-lg-4 mt-2 mt-md-3 mt-lg-5 mb-2 border-0 corners-rounded bg-gray': true}"
          style="width:  100%;">
          <div class="row g-0">
            <div class="col-3 col-md-4">
              <img src="assets/img/icons/con-4.png" class="img-fluid rounded-start mt-4"
                   style="width: 60%; margin-left: 15%;">
            </div>
            <div class="col-9 col-md-8">
              <div class="card-body p-0">
                <div class="d-flex justify-content-start">
                  <h5
                    [ngClass]="{'text-normal mb-1 mt-3': true, 'blue': !global.color_filter, 'black': global.color_filter, 'heavy-f': !global.dislex, 'dislex': global.dislex}">
                    +30 especialidades</h5>
                </div>
                <p
                  [ngClass]="{'p-0': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex, 'sizetext': global.sizetext}">
                  Disponibles para todos nuestros asegurados.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="mb-5">
  <div class="container">
    <div class="row">

      <div class="col-12 col-md-12 col-lg-12 col-xl-6">
        <div class="card bg-transparent border-0" style="width: 100%;margin-left: 0%">
          <div class="card-body">
            <h2
              [ngClass]="{'bigger-t title-line-height text-center mt-2 mt-md-4 mb-3 none-phone': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              NUESTROS PROGRAMAS DE<br>
              <span
                [ngClass]="{'': !global.color_filter, 'black': global.color_filter, 'p-1': !global.dislex, 'p-0': global.dislex}">CONVENIOS EMPRESARIALES</span>
            </h2>
            <h2
              [ngClass]="{'bigger-t title-line-height text-center mt-2 mt-md-4 mb-3 only-phone': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
              NUESTROS PROGRAMAS DE CONVENIOS EMPRESARIALES
            </h2>
            <p
              [ngClass]="{'title-line-height': true, 'text-muted': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              El objetivo principal de nuestros convenios es establecer una relación ganar ganar con cada empresa, la
              cual se convertirá en nuestro aliado comercial.</p>
            <p
              [ngClass]="{'mb-0':true, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Hemos desarrollado un programa de salud preventiva para todo el sector empresarial, nuestros servicios los
              brindamos a través de <strong>tres modalidades:</strong></p>

            <div class="container">
              <div class="row">

                <div class="col-12 col-md-4">
                  <h5
                    [ngClass]="{'text-normal mt-3': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                    Planes Anuales
                  </h5>
                </div>

                <div class="col-12 col-md-4">
                  <h5
                    [ngClass]="{'text-normal mt-3': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                    Ferias de Salud
                  </h5>
                </div>

                <div class="col-12 col-md-4">
                  <h5
                    [ngClass]="{'text-normal mt-3': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-arrow-right-short me-1" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                    Campañas
                  </h5>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>

      <div class="col-1 none-desktop"></div>

      <div class="col-12 col-md-10 col-lg-10 col-xl-6 none-phone">
        <h4
          [ngClass]="{'text-big mt-5': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          Entre los principales beneficios del Programa:</h4>
        <ul style="list-style: none; text-decoration: none; padding: 0;">
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Descuentos en nuestros servicios que van desde 5% hasta un 50%.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Descuentos para familiares de colaboradores o asociados.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Ferias en su empresa.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Charlas de salud preventiva.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Certificados y regalías para eventos especiales como
            Asambleas o Congresos.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Apoyo en comunicación y mercadeo.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Atención personalizada para consultas y trámite de citas.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Más de 30 especialidades.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                 class="bi bi-check-lg me-2 yellow" viewBox="0 0 16 16">
              <path
                d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"/>
            </svg>
            Más de 20 sucursales.
          </li>
        </ul>
      </div>

      <div class="col-12 only-phone">
        <h4
          [ngClass]="{'text-big mt-5': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          Entre los principales beneficios del Programa:</h4>
        <ul style="">
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Descuentos en nuestros servicios que van desde 5% hasta un 50%.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Descuentos para familiares de colaboradores o asociados.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Ferias en su empresa.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Charlas de salud preventiva.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Certificados y regalías para eventos especiales como
            Asambleas o Congresos.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Apoyo en comunicación y mercadeo.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Atención personalizada para consultas y tramite de citas.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Más de 30 especialidades.
          </li>
          <li [ngClass]="{'dislex': global.dislex, 'sizetext': global.sizetext}">
            Más de 20 sucursales.
          </li>
        </ul>
      </div>

      <div class="col-12 text-center">
        <h3
          [ngClass]="{'big-t mt-5': true, 'yellow': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          SOLICITA MÁS INFORMACIÓN</h3>
        <div class="d-flex justify-content-center">
          <div class="col-10">
            <a class="only-phone mt-3" href="tel:+50622855881"
               [ngClass]="{'btn btn-md-lg': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                   class="bi bi-telephone-fill me-1" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
              </svg>
              AGENDA TU CITA
            </a>
            <h3
              [ngClass]="{'big-t mt-1 none-phone': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex}">
              Agende cita con un ejecutivo de convenios al <strong>2285 5881</strong></h3>

            <h3
              [ngClass]="{'text-normal mb-4 mt-3 mt-md-2': true, 'turquesa': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex}">
              Escribanos al correo: convenioscr@asembis.org</h3>

          </div>
        </div>
      </div>

    </div>
  </div>
</section>

<section [ngClass]="{'gradient-2': !global.color_filter, 'bg-gray': global.color_filter}" #franquicias>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2
          [ngClass]="{'bigger-t-t title-line-height text-center mt-5 mt-md-5 mb-3 mb-md-3': true, 'white': !global.color_filter, 'black': global.color_filter , 'bold-f': !global.dislex, 'dislex': global.dislex}">
          CONOZCA SOBRE<br>
          FRANQUICIAS ASEMBIS
        </h2>
      </div>

      <div class="col-12 col-md-12 col-lg-6">
        <div class="card bg-transparent border-0" style="width: 100%;">
          <div class="card-body">
            <p
              [ngClass]="{'title-line-height': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-big': !global.sizetext, 'sizetext': global.sizetext}">
              Visítenos y compruebe, que en ASEMBIS, hacemos la diferencia.</p>
            <p
              [ngClass]="{'mb-3 mb-md-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'medium-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              El modelo de franquicia ASEMBIS es el primer modelo de franquicia social en Costa Rica. Una marca
              consolidada y en la que los costarricenses han confiado en sus servicios de atención médica durante las
              últimas 3 décadas.<br><br>
              Es un modelo de negocio muy interesante en el cual ASEMBIS le brinda el uso de la marca, además de todo el
              acompañamiento necesario para que su franquicia sea muy éxitosa.<br><br>
              <span class="bold-f">ASEMBIS SALUD AL ALCANCE DE TODOS</span>
            </p>

          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6 mb-2 mb-md-5">
        <h5
          [ngClass]="{'text-center text-big mt-0 mt-md-3': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          FRANQUICIAS EN EL PAÍS</h5>

        <div class="container">
          <div class="row d-flex justify-content-center">

            <div class="col-10 col-md-5 bg-white corners-rounded m-3 mt-3">
              <h2
                [ngClass]="{'text-big text-center mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                     class="bi bi-geo-alt-fill yellow" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                San José
              </h2>

              <h3
                [ngClass]="{'text-normal mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="ms-3 me-1 bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
                Santa Ana
              </h3>

              <h3
                [ngClass]="{'text-normal mt-2 mb-3': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="ms-3 me-1 bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
                La Merced
              </h3>
            </div>

            <div class="col-10 col-md-5 bg-white corners-rounded m-3 mt-3">
              <h2
                [ngClass]="{'text-big text-center mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                     class="bi bi-geo-alt-fill yellow" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                Alajuela
              </h2>

              <h3
                [ngClass]="{'text-normal mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="ms-3 me-1 bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
                San Ramón
              </h3>

              <h3
                [ngClass]="{'text-normal mt-2 mb-3': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="ms-3 me-1 bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
                Grecia
              </h3>
            </div>

            <div class="col-10 col-md-5 bg-white corners-rounded m-3 mt-3">
              <h2
                [ngClass]="{'text-big text-center mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                     class="bi bi-geo-alt-fill yellow" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                Cartago
              </h2>
            </div>

            <div class="col-10 col-md-5 bg-white corners-rounded m-3 mt-3">
              <h2
                [ngClass]="{'text-big text-center mt-2 mb-2': true, 'green': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                     class="bi bi-geo-alt-fill yellow" viewBox="0 0 16 16">
                  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                </svg>
                Heredia
              </h2>
            </div>

            <p
              [ngClass]="{'mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
              Si desea conocer con más detalle acerca de nuestro modelo de franquicias, comuníquese con nosotros:
            </p>

            <div class="row only-phone">
              <div class="col-12 d-grid">
                <a class="mt-4" href="tel:+50622855881"
                   [ngClass]="{'btn btn-md-lg me-3': true,'cursor': global.cursor_btn, 'btn-warning': !global.color_filter, 'btn-dark': global.color_filter}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                       class="bi bi-telephone-fill me-1" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>
                  AGENDA TU CITA
                </a>
                <h3
                  [ngClass]="{'text-normal text-center mt-2': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">
                  extensión 2103</h3>
              </div>
            </div>

            <div class="d-flex justify-content-md-center none-phone mt-md-3">
              <h3
                [ngClass]="{'text-big mt-2 me-2': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">
                Agenda tu cita al 2285 5881,</h3>
              <h3
                [ngClass]="{'text-normal mt-2': true, 'white': !global.color_filter, 'black': global.color_filter,'dislex': global.dislex}">
                extensión 2103</h3>
            </div>

            <h3
              [ngClass]="{'text-normal text-center mb-5 mb-md-2 white p-1 corners-rounded': true, 'white': !global.color_filter, 'black': global.color_filter, 'dislex': global.dislex}">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                   class="bi bi-envelope-fill mb-1 me-1" viewBox="0 0 16 16">
                <path
                  d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
              </svg>
              infofranquicias@asembis.org
            </h3>


          </div>
        </div>


      </div>

    </div>
  </div>
  <div class="">
    <img class="none-phone" src="assets/img/line-decor.png" alt="linea de decoración" style="width: 100%;">
    <img class="only-phone" src="assets/img/decor-m.jpg" alt="linea de decoración" style="width: 100%;">
  </div>
</section>

<section class="none-phone">
  <div
    [ngClass]="{'container corners-rounded mb-5 mt-5': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
    <div class="row">
      <div class="col-12 col-md-4 col-lg-2">
        <img class="mt-4 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">
      </div>

      <div class="col-12 col-md-8 col-lg-4">
        <h3
          [ngClass]="{'big-t mt-4 mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>
        <div class="d-flex justify-content-start mb-3">
          <a class="text-decoration-none me-4" style="cursor: pointer;"
             href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-small': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-instagram me-1 mb-1" viewBox="0 0 16 16">
                <path
                  d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
              </svg>
              clínicas_asembis
            </h6>
          </a>

          <a class="text-decoration-none" style="cursor: pointer;" href="https://www.facebook.com/Asembis/"
             target="_blank">
            <h6
              [ngClass]="{'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-small': !global.sizetext, 'sizetext': global.sizetext}">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                   class="bi bi-facebook me-1 mb-1"
                   viewBox="0 0 16 16">
                <path
                  d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg>
              Clínicas Asembis
            </h6>
          </a>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                </svg>
                TELÉFONOS
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Llamadas: +506 2285 5881</p>
              <p
                [ngClass]="{'ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                Whatsapp: +506 8814 5881</p>
            </div>
            <div class="col-12 col-md-6 center-ipad mb-md-4 mb-lg-0">
              <h5
                [ngClass]="{'text-big mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
                  <path
                    d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                </svg>
                CORREO
              </h5>
              <p
                [ngClass]="{'mb-0 ms-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
                contacto@asembis.org</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="only-phone">
  <div class="container mb-5 mt-5">
    <div class="row d-flex justify-content-center">

      <div
        [ngClass]="{'col-9 corners-rounded': true,'bg-turquesa': !global.color_filter, 'bg-gray': global.color_filter}">
        <img class="mt-5 mb-4 icon-contact-box" src="assets/img/icons/icon-contac-box.png">

        <h3
          [ngClass]="{'big-t mt-4 text-center mb-2': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          ESTAMOS SIEMPRE DISPUESTOS A ATENDERLES</h3>

        <div class="d-flex justify-content-center mb-5 mt-5">
          <a
            [ngClass]="{'text-decoration-none me-4': true, 'white': !global.color_filter, 'black': global.color_filter}"
            style="cursor: pointer;" href="https://www.instagram.com/clinicas_asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 class="bi bi-instagram me-3" viewBox="0 0 16 16">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
          </a>

          <a [ngClass]="{'text-decoration-none': true, 'white': !global.color_filter, 'black': global.color_filter}"
             style="cursor: pointer;" href="https://www.facebook.com/Asembis/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 class="bi bi-facebook ms-3"
                 viewBox="0 0 16 16">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
          </a>
        </div>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-telephone-fill me-2" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
          </svg>
          TELÉFONOS
        </h5>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Llamadas: +506 2285 5881</p>
        <p
          [ngClass]="{'text-center mb-0': true, 'white': !global.color_filter, 'black': global.color_filter, 'roboto': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          Whatsapp: +506 8814 5881</p>

        <h5
          [ngClass]="{'text-big text-center mt-4': true, 'white': !global.color_filter, 'black': global.color_filter, 'bold-f': !global.dislex, 'dislex': global.dislex}">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
               class="bi bi-envelope-fill me-2" viewBox="0 0 16 16">
            <path
              d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
          </svg>
          CORREOS
        </h5>
        <p
          [ngClass]="{'text-center mb-5': true, 'white': !global.color_filter, 'black': global.color_filter, 'light-f': !global.dislex, 'dislex': global.dislex, 'text-normal': !global.sizetext, 'sizetext': global.sizetext}">
          contacto@asembis.org</p>


      </div>

    </div>
  </div>
</section>

<app-foot></app-foot>
