import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Pipe, APP_INITIALIZER  } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';

import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { routing, appRoutingProviders } from './app.routing';
import { InicioComponent } from './components/inicio/inicio.component';
import { NavComponent } from './components/nav/nav.component';
import { FootComponent } from './components/foot/foot.component';
import { AsideComponent } from './components/aside/aside.component';
import { CreateCategoriaComponent } from './components/admin/categoria/create-categoria/create-categoria.component';
import { IndexCategoriaComponent } from './components/admin/categoria/index-categoria/index-categoria.component';
import { EditCategoriaComponent } from './components/admin/categoria/edit-categoria/edit-categoria.component';
import { CreateProductoComponent } from './components/admin/producto/create-producto/create-producto.component';
import { NgxTinymceModule } from 'ngx-tinymce';
import { MenuComponent } from './components/menu/menu.component';
import { CreateMarcaComponent } from './components/admin/marca/create-marca/create-marca.component';
import { IndexMarcaComponent } from './components/admin/marca/index-marca/index-marca.component';
import { EditMarcaComponent } from './components/admin/marca/edit-marca/edit-marca.component';
import { IndexProductoComponent } from './components/admin/producto/index-producto/index-producto.component';
import { EditProductoComponent } from './components/admin/producto/edit-producto/edit-producto.component';
import { GaleriaProductoComponent } from './components/admin/producto/galeria-producto/galeria-producto.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SelectorProductoComponent } from './components/admin/producto/selector-producto/selector-producto.component';
import { ColorProductoComponent } from './components/admin/producto/color-producto/color-producto.component';
import { PapeleraProductoComponent } from './components/admin/producto/papelera-producto/papelera-producto.component';
import { ProductosComponent } from './components/productos/productos.component';
import { ShowProductoComponent } from './components/show-producto/show-producto.component';
import { CarritoComponent } from './components/carrito/carrito.component';
import { PerfilComponent } from './components/cuenta/perfil/perfil.component';
import { AsideCuentaComponent } from './components/cuenta/aside-cuenta/aside-cuenta.component';
import { DireccionesComponent } from './components/cuenta/direcciones/direcciones.component';
import { CuponComponent } from './components/admin/cupon/cupon.component';

import { PostalComponent } from './components/admin/postal/postal.component';
import { IndexOrdenesComponent } from './components/cuenta/ordenes/index-ordenes/index-ordenes.component';
import { TrackOrdenComponent } from './components/cuenta/ordenes/track-orden/track-orden.component';
import { DetalleOrdenComponent } from './components/cuenta/ordenes/detalle-orden/detalle-orden.component';
import { IndexTicketComponent } from './components/cuenta/ordenes/index-ticket/index-ticket.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { AdminTicketComponent } from './components/admin/ticket/admin-ticket/admin-ticket.component';
import { IndexCancelacionComponent } from './components/admin/cancelacion/index-cancelacion/index-cancelacion.component';
import { DetalleCancelacionComponent } from './components/admin/cancelacion/detalle-cancelacion/detalle-cancelacion.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminVentasComponent } from './components/admin/ventas/admin-ventas/admin-ventas.component';
import { AdminDetalleventasComponent } from './components/admin/ventas/admin-detalleventas/admin-detalleventas.component';
import { JumboComponent } from './components/admin/jumbo/jumbo.component';
import { PortadaComponent } from './components/admin/config/general/portada.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { PromocionComponent } from './components/admin/promocion/promocion.component';
import { ErrorComponent } from './components/error/error.component';
import { ContactoAdminComponent } from './components/admin/contacto-admin/contacto-admin.component';
import { SliderComponent } from './components/admin/config/slider/slider.component';
import { DashboardComponent } from './components/admin/dashboard/dashboard.component';
import { IndexIngresoComponent } from './components/admin/ingreso/index-ingreso/index-ingreso.component';
import { CreateIngresoComponent } from './components/admin/ingreso/create-ingreso/create-ingreso.component';
import { DetalleIngresoComponent } from './components/admin/ingreso/detalle-ingreso/detalle-ingreso.component';

import { RecoveryComponent } from './components/recovery/recovery.component';
import { CollectionsComponent } from './components/collections/collections.component';
import { AboutdesignerComponent } from './components/aboutdesigner/aboutdesigner.component';
import { FAQComponent } from './components/faq/faq.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ShowArticleComponent } from './components/show-article/show-article.component';
import { PreguntaComponent } from './components/admin/pregunta/pregunta.component';
import { environment } from '../environments/environment';
import { locationComponent } from './components/admin/hitos/location.component';
import { AboutComponent } from './components/about/about.component';
import { PlanesComponent } from './components/planes/planes.component';
import { PromosComponent } from './components/promos/promos.component';
import { EspecialidadesComponent } from './components/especialidades/especialidades.component';
import { ConveniosComponent } from './components/convenios/convenios.component';
import { TrabajosocialComponent } from './components/trabajosocial/trabajosocial.component';
import { EspedashboardComponent } from './components/admin/espedashboard/espedashboard.component';
import { CitaComponent } from './components/cita/cita.component';
import { Loginv2Component } from './components/loginv2/loginv2.component';

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { GLOBAL } from "./services/GLOBAL";
import { GeneralinfoComponent } from './components/admin/generalinfo/generalinfo.component';
import { IndexArticleComponent } from './components/admin/article/index-article/index-article.component';
import { CreateArticleComponent } from './components/admin/article/create-article/create-article.component';
import { EditArticleComponent } from './components/admin/article/edit-article/edit-article.component';
import { PopUpFormComponent } from './components/admin/pop-up-form/pop-up-form.component';
import { PopUpService } from './services/popUp.service'; // Importar el servicio
import { Observable, tap } from 'rxjs';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function initializeApp(_popUpService: PopUpService): () => Observable<any> {
  return () => _popUpService.getPopUp().pipe(
    tap(popUp => _popUpService.setPopUp(popUp))
  );
}

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    NavComponent,
    FootComponent,
    AsideComponent,
    CreateCategoriaComponent,
    IndexCategoriaComponent,
    EditCategoriaComponent,
    CreateProductoComponent,
    MenuComponent,
    CreateMarcaComponent,
    IndexMarcaComponent,
    EditMarcaComponent,
    IndexProductoComponent,
    EditProductoComponent,
    GaleriaProductoComponent,
    SelectorProductoComponent,
    ColorProductoComponent,
    PapeleraProductoComponent,
    ProductosComponent,
    ShowProductoComponent,
    CarritoComponent,
    PerfilComponent,
    AsideCuentaComponent,
    DireccionesComponent,
    CuponComponent,
    PostalComponent,
    IndexOrdenesComponent,
    TrackOrdenComponent,
    DetalleOrdenComponent,
    IndexTicketComponent,
    DateAgoPipe,
    AdminTicketComponent,
    IndexCancelacionComponent,
    DetalleCancelacionComponent,
    AdminVentasComponent,
    AdminDetalleventasComponent,
    JumboComponent,
    PortadaComponent,
    ContactoComponent,
    PromocionComponent,
    ErrorComponent,
    ContactoAdminComponent,
    SliderComponent,
    DashboardComponent,
    IndexIngresoComponent,
    CreateIngresoComponent,
    DetalleIngresoComponent,
    RecoveryComponent,
    CollectionsComponent,
    AboutdesignerComponent,
    FAQComponent,
    ArticlesComponent,
    ShowArticleComponent,
    IndexArticleComponent,
    CreateArticleComponent,
    EditArticleComponent,
    PreguntaComponent,
    locationComponent,
    AboutComponent,
    PlanesComponent,
    PromosComponent,
    EspecialidadesComponent,
    ConveniosComponent,
    TrabajosocialComponent,
    EspedashboardComponent,
    CitaComponent,
    Loginv2Component,
    GeneralinfoComponent,
    PopUpFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule, // Asegurarse de que HttpClientModule esté importado
    routing,
    NgxTinymceModule.forRoot({
      baseURL: '../../assets/tinymce/',
    }),
    NgxDropzoneModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatStepperModule,
    NgbPaginationModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: 'c5767bdc-4d09-45d4-953b-3e854a0db019', // This is the ONLY mandatory field that you need to supply.
        authority: 'https://login.microsoftonline.com/7de65670-e2ae-4362-9e26-6608bd36868a', // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: 'https://asembis.org', // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
        postLogoutRedirectUri: 'https://asembis.org', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: true, // If "true", will navigate back to the original request location before processing the auth code response.
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback(logLevel: LogLevel, message: string) {
            console.log(message);
          },
          logLevel: LogLevel.Verbose,
          piiLoggingEnabled: false
        }
      }
    }),
      {
        interactionType: InteractionType.Redirect, // Msal Guard Configuration
        authRequest: {
          scopes: ["api://570cad47-a64a-455c-9107-410331cb729f/user_impersonation", "user.read", "offline_access"],
        },
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
          ["https://graph.microsoft.com/v1.0/users", ["Directory.ReadWrite.All", "User.ReadWrite.All"]],
          ["https://graph.microsoft.com/v1.0/invitations", ["Directory.ReadWrite.All"]],
          [GLOBAL.url + "cita/*", [
            {
              httpMethod: "POST",
              scopes: ["api://570cad47-a64a-455c-9107-410331cb729f/user_impersonation"]
            }
          ]],
        ]),
      }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [PopUpService],
      multi: true
    },
    PopUpService // Proveer el servicio
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
