import { Component, OnInit, DoCheck } from '@angular/core';
import { PortadaService } from 'src/app/services/portada.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { Portada } from '../../../../models/Portada';

interface HtmlInputEvent extends Event{
  target: HTMLInputElement & EventTarget;
}

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-general',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.css']
})
export class PortadaComponent implements OnInit, DoCheck {

  public filePortada: File;
  public filePortadaType: string;
  public filePortadaMobileType: string;
  public filePortadaMujer: File;
  public imgSelectPortada: String | ArrayBuffer;
  public imgSelectPortadaMujer: String | ArrayBuffer;
  public file_portada_mobile: File;
  public imgSelectPortadaMobile: String | ArrayBuffer;
  public portada: any = {};
  public url;
  public msm_error = '';
  public msm_success = false;
  public identity;
  public filesSlider: File[] = [];
  public filesSliderMobile: File[] = [];
  public extensionLists = {
    video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
    image: ['jpg', 'gif', 'bmp', 'png']
  };
  public desktopFileType: string;
  public mobileFileType: string;


  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _portadaService: PortadaService
  ) {
    this.identity = this._userService.getIdentity();
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    if (this.identity){
      if (this.identity.role == '12$MAhAAdPTi92gVknt8QyKIuEzcRYM6pa8.3RwTjFMfMwJvs2Jube'){
        this.init_data();

      }
      else{
        this._router.navigate(['/']);
      }
    }else{
      this._router.navigate(['/']);
    }

  }

  init_data(){
  }

  private async fetchFiles(response) {
    var slideMobile1 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider1Mobile).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider1Mobile ? response.imagenes.slider1Mobile : 'default', {type: blobFile.type}));
    var slideMobile2 = await fetch(this.url +
      'portada/resources/thumbnails/' +
      response.imagenes.slider2Mobile).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider2Mobile ? response.imagenes.slider2Mobile : 'default', {type: blobFile.type}));
    var slideMobile3 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider3Mobile).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider3Mobile ? response.imagenes.slider3Mobile : 'default', {type: blobFile.type}));

    var slide1 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider1).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider1 ? response.imagenes.slider1 : 'default', {type: blobFile.type}));
    var slide2 = await fetch(this.url +
      'portada/resources/thumbnails/' +
      response.imagenes.slider2).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider2 ? response.imagenes.slider2 : 'default', {type: blobFile.type}));
    var slide3 = await fetch(this.url + 'portada/resources/thumbnails/' +
      response.imagenes.slider3).then(r => r.blob()).then(blobFile =>
      new File([blobFile], response.imagenes.slider3 ? response.imagenes.slider3 : 'default', {type: blobFile.type}));

    if (slideMobile1.name !== 'default' && slideMobile2.name !== 'default' && slideMobile3.name !== 'default') {
      this.filesSliderMobile = [
        slideMobile1, slideMobile2, slideMobile3
      ];
    }
    if (slide1.name !== 'default' && slide2.name !== 'default' && slide3.name !== 'default') {
      this.filesSlider = [
        slide1, slide2, slide3
      ];
    }
  }

  close_alert(){
    this.msm_error = '';
  }

  onSelectSlider(event) {
    this.filesSlider.push(...event.addedFiles);
  }

  onRemoveSlider(event) {
    this.filesSlider.splice(this.filesSlider.indexOf(event), 1);
  }

  onSelectSliderMobile(event) {
    this.filesSliderMobile.push(...event.addedFiles);
  }

  onRemoveSliderMobile(event) {
    this.filesSliderMobile.splice(this.filesSlider.indexOf(event), 1);
  }


  imgSelected_portada(event: HtmlInputEvent){
    if (event.target.files  && event.target.files[0]){
      this.imgSelectPortada = undefined;
        this.filePortada = (event.target.files[0] as File);

        const reader = new FileReader();
        reader.onload = e =>
          this.imgSelectPortada = reader.result;
        if (this.extensionLists.video.includes(this.filePortada.name.split('.').pop())) {
          this.desktopFileType = 'video';
        } else if (this.extensionLists.image.includes(this.filePortada.name.split('.').pop())) {
          this.desktopFileType = 'image';
        }
        reader.readAsDataURL(this.filePortada);
        this.portada.portada = this.filePortada;
        $('#logo-icon').addClass('cz-file-drop-preview img-thumbnail rounded logo-preview');
        $('#logo-icon').removeClass('cz-file-drop-icon czi-cloud-upload');


    }

  }

  imgSelectedPortadaMobile(event: HtmlInputEvent){
    if (event.target.files  && event.target.files[0]){
      this.imgSelectPortadaMobile = undefined;
        this.file_portada_mobile = (event.target.files[0] as File);
        if (this.extensionLists.video.includes(this.file_portada_mobile.name.split('.').pop())) {
          this.mobileFileType = 'video';
        } else if (this.extensionLists.image.includes(this.file_portada_mobile.name.split('.').pop())) {
          this.mobileFileType = 'image';
        }
        const reader = new FileReader();
        reader.onload = e =>
          this.imgSelectPortadaMobile = reader.result;
        reader.readAsDataURL(this.file_portada_mobile);
        this.portada.portadaMobile = this.file_portada_mobile;
        $('#favicon-icon').addClass('cz-file-drop-preview img-thumbnail rounded favicon-preview');
        $('#favicon-icon').removeClass('cz-file-drop-icon czi-cloud-upload');


    }

  }

  imgSelectedPortadaMujer(event: HtmlInputEvent){
    if (event.target.files  && event.target.files[0]){
      this.imgSelectPortadaMujer = undefined;
      this.filePortadaMujer = (event.target.files[0] as File);

      const reader = new FileReader();
      reader.onload = e =>
        this.imgSelectPortadaMujer = reader.result;
      if (this.extensionLists.image.includes(this.filePortadaMujer.name.split('.').pop())) {
        this.desktopFileType = 'image';
      }
      reader.readAsDataURL(this.filePortadaMujer);
      this.portada.portadaMujer = this.filePortadaMujer;
      $('#logo-icon').addClass('cz-file-drop-preview img-thumbnail rounded logo-preview');
      $('#logo-icon').removeClass('cz-file-drop-icon czi-cloud-upload');


    }

  }

  ngDoCheck(): void {

    $('.logo-preview').html('<img src=' + this.imgSelectPortada + '>');
    $('.favicon-preview').html('<img src=' + this.imgSelectPortadaMobile + '>');
  }

  onkey_iframe(data){

    $('#content-mapa').html(data);
  }

  onSubmit(generalForm){
    if ((this.filesSlider.length === 3) && (this.filesSliderMobile.length === 3)) {
      const data = {
        tipo: this.portada.tipo,
        portada: this.filePortada,
        portadaMobile: this.file_portada_mobile,
        slider1: this.filesSlider[0],
        slider1Mobile: this.filesSliderMobile[0],
        slider2: this.filesSlider[1],
        slider2Mobile: this.filesSliderMobile[1],
        slider3: this.filesSlider[2],
        slider3Mobile: this.filesSliderMobile[2],
        portadaMujer: this.filePortadaMujer,
      };

      this._portadaService.update(this.portada._id, data).subscribe(
        response => {
          this.msm_error = '';
          this.msm_success = true;
          window.scroll(0, 0);
        },
        error => {
          window.scroll(0, 0);
          this.msm_error = error.message;
          this.msm_success = false;
        }
      );
    }
    else {
        this.msm_error = 'Agregue únicamente 3 imágenes para cada Slider';
    }
  }
}
